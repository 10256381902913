<template>
	<div class="page-meeting-minutes">
		<div class="row justify-content-center">
			<div class="col-lg-11 col-xl-10">
				<div class="card mb-4">
					<div class="card-body">
						<h2 class="card-title text-center mt-4 mb-2">{{ meeting.title }}</h2>
						<h3 class="card-title text-center mb-5">Meeting minutes</h3>

						<iframe
							v-if="meeting.minutes_status === 'manual-upload'"
							:src="getPublicFileUrl(meeting.meeting_minutes_file_path)"
							width="100%"
							height="1000"
							frameborder="0"
						></iframe>

						<template v-else>
							<!-- MAKE LONGER OPTIONS -->
							<div
								v-if="states.makeLongerAdvancedOptions"
								class="border border-danger p-2 rounded-1 mb-3"
							>
								<p class="text-center">Options for "Make Longer" AI action</p>
								<p>
									<span class="font-monospace mx-2">
										Temperature
										<code>{{ Number(makeLongerOptions.temperature).toFixed(1) }}</code>
									</span>

									<input
										type="range"
										class="d-inline-block form-range form-range-sm"
										style="max-width: 200px"
										v-model="makeLongerOptions.temperature"
										min="0"
										max="1.5"
										step="0.1"
									/>
								</p>
								<p>Prompt</p>
								<textarea
									class="form-control form-control-sm mb-1"
									v-model="makeLongerOptions.prompt"
									rows="12"
								/>
							</div>

							<!-- MAKE SHORTER OPTIONS -->
							<div
								v-if="states.makeShorterAdvancedOptions"
								class="border border-danger p-2 rounded-1 mb-3"
							>
								<p class="text-center">Options for "Make Shorter" AI action</p>
								<p>
									<span class="font-monospace mx-2">
										Temperature
										<code>{{ Number(makeShorterOptions.temperature).toFixed(1) }}</code>
									</span>
									<input
										type="range"
										class="d-inline-block form-range form-range-sm"
										style="max-width: 200px"
										v-model="makeShorterOptions.temperature"
										min="0"
										max="1.5"
										step="0.1"
									/>
								</p>
								<p>Prompt</p>
								<textarea
									class="form-control form-control-sm mb-1"
									v-model="makeShorterOptions.prompt"
									rows="12"
								/>
							</div>

							<!-- DESCRIBE YOUR CHANGE OPTIONS -->
							<div
								v-if="states.describeYourChangeAdvancedOptions"
								class="border border-danger p-2 rounded-1 mb-3"
							>
								<p class="text-center">Options for "Describe Your Change" AI action</p>
								<p>
									<span class="font-monospace mx-2">
										Temperature
										<code>{{ Number(describeYourChangeOptions.temperature).toFixed(1) }}</code>
									</span>
									<input
										type="range"
										class="d-inline-block form-range form-range-sm"
										style="max-width: 200px"
										v-model="describeYourChangeOptions.temperature"
										min="0"
										max="1.5"
										step="0.1"
									/>
								</p>
								<p>Prompt</p>
								<textarea
									class="form-control form-control-sm mb-1"
									v-model="describeYourChangeOptions.prompt"
									rows="12"
								/>
							</div>

							<p
								v-if="meeting.minutes_status === 'missing'"
								class="lead text-center text-neutral-500 mb-5"
							>
								<i>This meeting has no minutes yet</i>
							</p>

							<editor
								v-else
								v-model="meeting.minutes_text"
								@input="states.minutesUpdated = true"
								@ai-action="handleAIAction"
								@make-longer-context-menu="handleAIActionContextMenu('make_longer')"
								@make-shorter-context-menu="handleAIActionContextMenu('make_shorter')"
								@describe-your-change-context-menu="handleAIActionContextMenu('describe_your_change')"
								:placeholder="
									meeting.minutes_status === 'generating'
										? 'Sit back, relax, and watch the minutes being written..'
										: 'Write the meeting minutes here'
								"
							/>

							<div
								v-if="['done', 'fresh-done'].includes(meeting.minutes_status)"
								class="row justify-content-between meeting-editor-toolbar mb-2"
							>
								<div class="col-auto">
									<button
										v-if="states.minutesUpdated"
										class="btn btn-sm btn-primary"
										@click="saveMinutes"
									>
										Save minutes
									</button>
								</div>
								<div class="col-auto">
									<div class="dropdown">
										<button
											class="btn btn-sm btn-outline-primary dropdown-toggle"
											type="button"
											id="download-minutes"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											Download minutes
										</button>
										<ul class="dropdown-menu" aria-labelledby="download-minutes">
											<li>
												<a
													class="dropdown-item"
													:href="
														`${apiUrl + j.slug}/meetings/${
															meeting.pid
														}/minutes/save/docx?person_id=${auth.id}`
													"
													@click="minutesSaveAs('docx')"
													><font-awesome-icon
														:icon="['fas', 'file']"
														class="text-primary me-1"
													/>
													Word document</a
												>
											</li>
											<li>
												<button class="dropdown-item" @click="minutesSaveAs('pdf', 'no')">
													<font-awesome-icon
														:icon="['fas', 'file-pdf']"
														class="text-danger me-1"
													/>
													PDF document
												</button>
											</li>
										</ul>
									</div>
								</div>
							</div>

							<div
								v-if="meeting.minutes_status === 'fresh-done'"
								class="bg-neutral-50 border border-primary rounded-1 p-3 mx-6 my-5"
							>
								<button type="button" class="btn-close float-end" @click="feedbackClose"></button>
								<h4 class="mb-4">🙏 Let us know how we did</h4>

								<form @submit.prevent="feedbackSend">
									<div class="form-group mb-3">
										<label class="form-label">How good was the output for these minutes?</label>

										<div class="text-primary-400">
											<span
												v-for="star in [1, 2, 3, 4, 5]"
												:key="star"
												class="display-5 cursor-pointer"
												:class="
													star <= feedback.rating ? 'text-warning-300' : 'text-neutral-300'
												"
												@click="setRating(star)"
												>★</span
											>
										</div>
									</div>

									<template v-if="feedback.rating && feedback.rating < 5">
										<label class="form-label">Tell us what happened</label>
										<div class="mb-3">
											<button
												v-for="(optionValue, optionKey) in feedbackOptions"
												:key="optionKey"
												class="btn btn-outline-info btn-sm me-2 mb-2"
												:class="{
													'btn-primary': states.activeFeedbackOptions.includes(optionKey),
												}"
												type="button"
												@click="toggleActiveFeedbackOptions(optionKey)"
											>
												{{ optionValue.text }}
											</button>
										</div>

										<div
											v-for="hint in feedbackHints"
											:key="hint"
											class="alert alert-info text-dark"
											v-html="hint"
										></div>

										<div
											v-if="states.activeFeedbackOptions.includes('other')"
											class="form-group mb-3"
										>
											<label class="form-label">
												<span v-if="feedback.rating > 3">
													What else needs to be improved for these minutes?
												</span>
												<span v-else>
													What do we need to fix on our end to improve the minutes?
												</span>
											</label>
											<textarea
												class="form-control"
												rows="3"
												v-model="feedback.message"
												placeholder="Your feedback helps us improve ClerkMinutes"
												required
											>
											</textarea>
										</div>

										<p class="text-center mb-0">
											<button type="submit" class="btn btn-primary" :disabled="!feedback.rating">
												Submit feedback
											</button>
										</p>
									</template>
								</form>
							</div>

							<div
								v-if="meeting.minutes_status !== 'public' && meeting.minutes_status !== 'generating'"
								class="bg-ai border rounded-1 px-3 py-4 my-5 mx-6"
								:class="{ 'cursor-pointer': !states.showGenerateMinutes }"
								@click="states.showGenerateMinutes = true"
							>
								<h5
									class="text-center my-0"
									@dblclick="
										states.generateDraftAdvancedOptions = !states.generateDraftAdvancedOptions
									"
								>
									✨
									{{
										meeting.minutes_status === 'missing'
											? 'Generate draft meeting minutes'
											: 'Re-generate minutes'
									}}
								</h5>

								<template v-if="states.showGenerateMinutes">
									<div class="onboarding bg-dasnger-50 p-3 mt-3">
										<div class="row align-items-center hover rounded-1 gap-0 mb-1">
											<div class="col-auto">
												<div
													class="py-1 px-2 rounded"
													:class="
														meeting.agenda_items.length > 4
															? 'bg-success-50'
															: 'bg-danger-50'
													"
												>
													<font-awesome-icon
														v-if="meeting.agenda_items.length > 4"
														:icon="['fas', 'check']"
														class="text-success-400 fa-fw"
													/>
													<font-awesome-icon
														v-else
														:icon="['fas', 'plus']"
														class="text-danger-400 fa-fw"
													/>
												</div>
											</div>
											<div class="col py-2">
												<h6 v-if="meeting.agenda_items.length > 2" class="mb-1">
													Agenda is looking good with {{ meeting.agenda_items.length }} items
												</h6>
												<h6 v-else class="mb-1">
													<router-link :to="`/${j.slug}/meetings/${meeting.pid}/agenda`"
														>Add at least 3 agenda items</router-link
													>
												</h6>
												<p class="mb-0">Minutes structure is based on the agenda items</p>
											</div>
										</div>
										<div class="row align-items-center hover rounded-1 gap-0 mb-1">
											<div class="col-auto">
												<div
													class="py-1 px-2 rounded"
													:class="
														meeting.transcript_job_status === 'transcribed'
															? 'bg-success-50'
															: 'bg-danger-50'
													"
												>
													<font-awesome-icon
														v-if="meeting.transcript_job_status === 'transcribed'"
														:icon="['fas', 'check']"
														class="text-success-400 fa-fw"
													/>
													<font-awesome-icon
														v-else
														:icon="['fas', 'plus']"
														class="text-danger-400 fa-fw"
													/>
												</div>
											</div>
											<div class="col py-2">
												<h6 class="mb-1">
													<template v-if="meeting.transcript_job_status === 'transcribed'">
														Recording transcript is ready
													</template>
													<router-link
														v-else
														:to="`/${j.slug}/meetings/${meeting.pid}/transcript`"
														>Add meeting recording</router-link
													>
												</h6>
												<h6 class="mb-1"></h6>
												<p class="mb-0">We'll use the transcript from the recording</p>
											</div>
										</div>
										<div class="row align-items-center hover rounded-1 gap-0 mb-1">
											<div class="col-auto">
												<div
													class="py-1 px-2 rounded"
													:class="
														meeting.transcript_job_status === 'transcribed'
															? 'bg-primary-50'
															: 'bg-danger-50'
													"
												>
													<font-awesome-icon
														v-if="meeting.transcript_job_status === 'transcribed'"
														:icon="['fas', 'info']"
														class="text-primary-400 fa-fw"
													/>
													<font-awesome-icon
														v-else
														:icon="['fas', 'question']"
														class="text-danger-400 fa-fw"
													/>
												</div>
											</div>
											<div class="col py-2">
												<h6 class="mb-1">Identify speakers in transcript</h6>
												<p class="mb-0">
													Speakers name are used for motions and quotes in the minutes
												</p>
											</div>
										</div>
									</div>

									<p class="text-center mb-2">
										Choose how you'd like your draft minutes to be created:
									</p>

									<p class="text-center">
										<label>Length:</label>
										<select
											class="d-inline-block form-select form-select-sm mx-2"
											style="max-width: 200px"
											v-model="regenerateDraftOptions.length"
										>
											<option value="short-and-to-the-point">Short</option>
											<option value="medium-length">Medium</option>
											<option value="long-and-descriptive">Make it long</option>
										</select>

										<label>Tone:</label>
										<select
											class="d-inline-block form-select form-select-sm mx-2"
											style="max-width: 200px"
											v-model="regenerateDraftOptions.tone"
										>
											<option value="official-meeting-speak">Official meeting</option>
											<option value="formal">Make it sound more formal</option>
											<option value="professional-corporate">Professional</option>
											<option value="overly-energetic-and-enthusiastic">Enthusiastic</option>
											<option value="casual">Make it sound casual</option>
											<option value="in-southern-belle-speaking-with-a-southern-drawl"
												>Make it sound like a Southerner</option
											>
											<option value="like-spoken-by-a-true-texan"
												>Make it sound like spoken by a Texan</option
											>
											<option value="over-the-top-like-a-wisconsinite"
												>Make it sound like a Wisconsinite</option
											>
										</select>
									</p>

									<p class="text-center">
										Click “Generate” to start it. You can always tweak the options and re-generate.
									</p>

									<div
										v-if="isStaff && states.generateDraftAdvancedOptions"
										class="border border-danger p-2 rounded-1 mb-3"
									>
										<p class="text-center">
											Options for HeyGov:
											<select
												class="d-inline-block form-select form-select-sm mx-2"
												style="max-width: 160px"
												v-model="regenerateDraftOptions.model"
											>
												<option value="gpt-4-turbo">OpenAI: GPT-4 Turbo</option>
												<option value="gpt-4o">OpenAI: GPT-4o</option>
												<option value="claude-3-5-sonnet-20240620"
													>Anthropic: Claude 3.5 Sonnet</option
												>
											</select>

											<span class="font-monospace mx-2"
												>Temp
												<code>{{
													Number(regenerateDraftOptions.temperature).toFixed(1)
												}}</code></span
											>

											<input
												type="range"
												class="d-inline-block form-range form-range-sm"
												style="max-width: 200px"
												v-model="regenerateDraftOptions.temperature"
												min="0"
												max="1.5"
												step="0.1"
											/>
										</p>

										<textarea
											class="form-control form-control-sm mb-1"
											v-model="regenerateDraftOptions.prompt"
											rows="12"
										></textarea>
										<a :href="minutesPromptUrl" target="_blank"><small>Show full prompt</small></a>
									</div>

									<p class="text-center mb-0">
										<button
											class="btn btn-primary"
											:disabled="
												meeting.agenda_items.length < 3 ||
													meeting.transcript_job_status !== 'transcribed'
											"
											@click="generateDraftMinutes"
										>
											✨ Generate draft minutes
										</button>
									</p>
								</template>
							</div>

							<div
								class="minutes-generating bg-success-50 border p-3 rounded"
								:class="{ show: meeting.minutes_status === 'generating' }"
							>
								<div class="row align-items-center gx-3">
									<div class="col-auto">
										<img
											src="https://edge.heygov.com/app-assets/loader-ball-triangle.svg"
											class="bg-success-100 rounded-1 p-1"
											height="50"
											alt="Generating minutes"
										/>
									</div>
									<div class="col">
										Minutes are being generated right now
									</div>
									<div class="col-auto">
										<button class="btn btn-sm" @click="minutesAbortController.abort()">⏹️</button>
									</div>
								</div>
							</div>

							<template v-if="meeting.minutes_status === 'missing'">
								<p class="text-center text-neutral-300">- or -</p>

								<div class="bg-light border border-dashed text-center rounded-1 p-4 mb-3 mx-6">
									<label for="meeting-minutes-file" class="btn btn-sm btn-outline-primary">
										<font-awesome-icon :icon="['fas', 'file-import']" class="me-1" />
										Upload minutes file
									</label>
								</div>
							</template>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
@import '@/assets/variables';

.meeting-minutes-editor {
	.ql-editor {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-top: 1.7rem;
			margin-bottom: 0.7rem;

			&:first-child {
				margin-top: 0;
			}
		}

		p {
			margin-bottom: 0.7rem;
		}

		blockquote {
			border-color: $primary-50;
		}
	}
}

.minutes-generating {
	position: fixed;
	bottom: -6rem;
	left: 50%;
	width: 500px;
	margin-left: -250px;
	transition: bottom 0.4s ease-in-out;
}

.minutes-generating.show {
	bottom: 1rem;
}
</style>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import { Converter } from 'showdown'
import Editor from '../../components/editor/Editor.vue'

import { hgApi } from '@/api.js'
import { getPublicFileUrl, sendEvent } from '@/utils.js'

export default {
	name: 'MeetingMinutes',
	components: { Editor },
	metaInfo() {
		return {
			title: `Minutes for ${this.meeting?.title || this.$route.params.meetingId} - Meetings`,
		}
	},
	props: {
		meeting: {
			type: Object,
			required: true,
		},
		meetingPlayer: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			feedbackOptions: {
				agendaItemsMissing: {
					text: 'Missing or incorrect agenda items',
					hint:
						'Agenda items can be added or edited in the <strong>Agenda</strong> tab. <a href="https://townweb.notion.site/Create-and-edit-Agenda-items-fb3152b74c3e4699b75c52d473c23618" target="_blank"></a>',
				},
				transcriptMissedDetails: {
					text: 'Short minutes, missed details',
					hint:
						'Have you tried the "Make longer" AI action? <a href="https://www.notion.so/townweb/Add-details-or-lengthen-your-minutes-with-AI-Actions-b08a30bab468441a9afcd3462f730dff" target="_blank">Learn how here</a>',
				},
				speakerNameMissing: {
					text: 'No speaker names',
					hint:
						'Have you assigned all speakers in the <strong>Transcript</strong> tab? <a href="https://www.notion.so/townweb/Assign-speakers-for-your-meeting-90f25ad01de447cabbe25545d32255f0" target="_blank">Learn how here.<a>',
				},
				//factualErrors: { text: 'Minutes had factual errors', hint: '' },
				toneIncorrect: {
					text: 'Not the tone/style that I wanted',
					hint: `Tone/style can be changed by selecting the text, and using the "AI Actions -> Rewrite in style.." option`,
				},
				other: { text: 'Other' },
			},
			states: {
				showGenerateMinutes: this.meeting.minutes_status === 'missing',
				generateDraftAdvancedOptions: false,
				minutesUpdated: false,
				makeLongerAdvancedOptions: false,
				makeShorterAdvancedOptions: false,
				describeYourChangeAdvancedOptions: false,
				activeFeedbackOptions: [],
			},
			minutesMarkdown: '',
			minutesAbortController: null,
			makeLongerOptions: {
				temperature: 1.1,
				prompt: `
You are ClerkGPT - an intelligent and helpful assistant that helps with {jurisdiction_type} meetings in {jurisdiction_name} while following these:
- If a motion was made, include it in a quote block, with the result, who made it and who seconded it. If names are not known, use placeholder ____. Add a extra line break after the quote block
- Always use the input data to accomplish this task and never invent any information
- Use the same language and style as the document itself
- The entire answer should be ready to be copied and pasted, without any preamble. Only return the longer version of the selected section, not the whole document
- format content with markdown

You will be provided with:
- Agenda items: what the clerks planned to discuss.
- Transcript: dialog from meeting transcribed from meeting recording.
- Meeting Minutes: minutes based on agenda items & transcript.
- Selection - a specific section of the minutes that needs editing.

Agenda items:
{agenda_items}
---
Transcript:
{transcript}
---
Meeting minutes:
{meeting_minutes}
---
Selection:
{selection}
---

The task: expand the selection to be at least double in size, and max 3x of the original. Use only the details from the transcript to do this.
`,
			},
			makeShorterOptions: {
				temperature: 1.1,
				prompt: `
You are ClerkGPT - an intelligent and helpful assistant.
Your task is to shorten certain sections in the meeting minutes document.
While doing so, you should always follow these guidelines:

- Use the input data to accomplish this task and never invent any information.
- Use the same language and style as the document itself.
- Use markdown.
- Entire answer should be ready to be copied and pasted, without any preamble.
- Only return the shorter version of the selected section, not the whole document.
- Shortened section must be twice shorter than original selection.

You will be provided with this input data:

1. Agenda items - what the clerks planned to discuss.
2. Transcript - the actual recording of their conversation.
3. Meeting minutes - a text document created based on the transcript.
4. Selection - a specific section of the meeting minutes document that needs to be shortened.

===

Agenda items:

{agenda_items}

---

Transcript:

{transcript}

---

Meeting minutes:

{meeting_minutes}

---

Selection:

{selection}`,
			},
			describeYourChangeOptions: {
				temperature: 1.1,
				prompt: `
You are ClerkGPT - an intelligent and helpful assistant.
Your task is to rewrite certain sections in the meeting minutes document.
While doing so, you should always follow these guidelines:

- Use the input data to accomplish this task and never invent any information.
- Use the same language as the document itself.
- Use markdown.
- Entire answer should be ready to be copied and pasted, without any preamble.
- Only return the rewritten version of the selected section, not the whole document.
- Selected section must rewritten exactly in a way, user described in the prompt.

You will be provided with this input data:

1. Agenda items - what the clerks planned to discuss.
2. Transcript - the actual recording of their conversation.
3. Meeting minutes - a text document created based on the transcript.
4. Selection - a specific section of the meeting minutes document that needs to be shortened.
5. Prompt - a description of how the selected section should be rewritten.

===

Agenda items:

{agenda_items}

---

Transcript:

{transcript}

---

Meeting minutes:

{meeting_minutes}

---

Selection:

{selection}

---

Prompt:

{prompt}
`,
			},
			regenerateDraftOptions: {
				model: 'gpt-4o',
				temperature: 1.1,
				length: 'medium-length',
				tone: 'professional-corporate',
				prompt: `Here are the details for a {jurisdiction_type} meeting named "{meeting_title}" in {jurisdiction_name}.

Meeting transcript:
{transcript}

Meeting agenda:
{agenda_items}

I want you to act as the {jurisdiction_type} clerk. Help us create {length} meeting minutes sounding {tone} for each agenda item:
- You will summarize the text in past tense and use gender-neutral terminology for each speaker;
- You must ensure that the summary accurately reflects the meeting and leaves out bias, personal opinion, and non-relevant information;
- the summary should set the state for what happened in the meeting;
- don't include meeting title or general heading;
- format content with markdown;
- main agenda items should be second-level heading, sub-items should be third-level heading, and each agenda item followed by its summary/minutes;
- If a motion was made, include it in a quote block, with the result, who made it and who seconded it. If names are not known, use placeholder ____. Add a extra line break after the quote block;
{extra_consent_agenda}`,
			},
			ratingTimeout: null,
			feedback: { message: '', rating: 0 },
		}
	},
	computed: {
		...mapGetters(['auth', 'isStaff']),
		...mapState(['j', 'apiUrl']),
		minutesPromptUrl() {
			const url = new URL(`${this.apiUrl + this.j.slug}/meetings/${this.meeting.pid}/minutes-full-prompt`)

			for (const param in this.regenerateDraftOptions) {
				url.searchParams.set(param, this.regenerateDraftOptions[param])
			}

			return url
		},
		feedbackHints() {
			return this.states.activeFeedbackOptions.map(k => this.feedbackOptions[k].hint).filter(Boolean)
		},
	},
	created() {
		setTimeout(() => {
			this.states.minutesUpdated = false
		}, 500)
	},
	mounted() {
		if (this.meetingPlayer.position === 'default') {
			this.$emit('playerStyles', {
				position: 'fixed',
				width: `400px`,
				// top: `${$playerPosition.top + window.scrollY}px`,
				// left: `${$playerPosition.left}px`,
				right: '16px',
				bottom: '16px',
			})
		}
	},
	methods: {
		getPublicFileUrl,

		/**
		 * Adds ot removes given feedback option from the activeFeedbackOptions array.
		 *
		 * @param {Object} feedbackOption - The selected string to be processed.
		 * @returns {void}
		 */
		toggleActiveFeedbackOptions(optionKey) {
			if (this.states.activeFeedbackOptions.includes(optionKey)) {
				this.states.activeFeedbackOptions = this.states.activeFeedbackOptions.filter(k => k != optionKey)
			} else {
				this.states.activeFeedbackOptions.push(optionKey)
			}
		},

		handleAIActionContextMenu(command) {
			if (!this.isStaff) {
				return
			}

			// disable all
			this.states.makeLongerAdvancedOptions = false
			this.states.makeShorterAdvancedOptions = false
			this.states.describeYourChangeAdvancedOptions = false

			// enable selected
			switch (command) {
				case 'make_longer':
					this.states.makeLongerAdvancedOptions = true
					break
				case 'make_shorter':
					this.states.makeShorterAdvancedOptions = true
					break
				case 'describe_your_change':
					this.states.describeYourChangeAdvancedOptions = true
					break
			}

			alert('AI Action options available at the top until you reload the page.')
		},

		async handleAIAction(payload) {
			const { command, prompt, fullText, selection, update } = payload

			const options = {
				make_longer: this.makeLongerOptions,
				make_shorter: this.makeShorterOptions,
				describe_your_change: this.describeYourChangeOptions,
			}[command]

			let response
			try {
				response = await hgApi(`${this.j.slug}/meetings/${this.meeting.pid}/minutes/regenerate-selection`, {
					json: {
						command: command,
						prompt: prompt,
						fullText: fullText,
						selection: selection,
						options: options,
					},
				})
			} catch (error) {
				Vue.toasted.error(`Failed to generate minutes (${error.message})`)
				console.error(error)
				return
			}

			const reader = response.body.getReader()
			const decoder = new TextDecoder()

			// eslint-disable-next-line no-constant-condition
			while (true) {
				let chunk
				try {
					chunk = await reader.read()
				} catch (error) {
					// @ts-ignore
					Vue.toasted.error(`Failed to generate minutes (${error.message})`)
					console.error(error)
					return
				}

				const { done, value } = chunk
				const decodedMD = decoder.decode(value, { stream: true })

				update(decodedMD, done) // send data back to the editor

				if (done) {
					break // <- end of loop
				}
			}
		},

		generateDraftMinutes() {
			const previousStatus = this.meeting.minutes_status
			this.meeting.minutes_status = 'generating'
			this.meeting.minutes_text = ''
			this.minutesMarkdown = ''

			const converter = new Converter()
			converter.setOption('simplifiedAutoLink', true)
			converter.setOption('openLinksInNewWindow', true)

			this.minutesAbortController = new AbortController()

			hgApi(`${this.j.slug}/meetings/${this.meeting.pid}/generate-minutes-stream`, {
				body: this.regenerateDraftOptions,
				signal: this.minutesAbortController.signal,
			})
				.then(response => {
					const reader = response.body.getReader()
					const decoder = new TextDecoder()

					const read = () => {
						reader
							.read()
							.then(({ done, value }) => {
								if (done) {
									this.meeting.minutes_status = 'fresh-done'
									// done

									this.states.showGenerateMinutes = false
									this.states.minutesUpdated = false
								} else {
									const chunk = decoder.decode(value, { stream: true })

									this.minutesMarkdown += chunk

									this.meeting.minutes_text = converter.makeHtml(this.minutesMarkdown)

									read()
								}
							})
							.catch(error => {
								if (error.name === 'AbortError') {
									this.meeting.minutes_status = 'fresh-done'
									this.states.showGenerateMinutes = true
								} else {
									Vue.toasted.error(`Failed to generate minutes (${error.message})`)
									console.error(error)
								}
							})
					}

					read()
				})
				.catch(error => {
					this.meeting.minutes_status = previousStatus
					Vue.toasted.error(`Failed to generate minutes (${error.message})`)
					console.error(error)
				})

			sendEvent('Generate minutes', {
				feature: 'ClerkMinutes',
				meeting_id: this.meeting.pid,
				meeting: this.meeting.title,
			})
		},

		saveMinutes() {
			this.$emit('updateMeeting', {
				fields: {
					minutes_text: this.meeting.minutes_text,
				},
				message: 'Minutes are saved',
			})
			this.states.minutesUpdated = false
		},

		minutesSaveAs(as, implemented = 'yes') {
			if (implemented === 'no') {
				Vue.toasted.error('not implemented yet')
			}

			sendEvent('Save minutes', {
				feature: 'ClerkMinutes',
				meeting_id: this.meeting.pid,
				meeting: this.meeting.title,
				saveas: as,
				implemented,
			})
		},

		setRating(minutes_rating) {
			minutes_rating = Number(minutes_rating)
			clearTimeout(this.ratingTimeout)
			this.feedback.rating = minutes_rating

			hgApi(`${this.j.slug}/meetings/${this.meeting.pid}`, {
				method: 'PUT',
				json: { minutes_rating },
			})

			if (minutes_rating === 5) {
				this.ratingTimeout = setTimeout(() => {
					this.$emit('updateMeeting', {
						fields: { minutes_status: 'done' },
						message: 'Thanks for your feedback 🤝',
					})
				}, 5000)
			}
		},

		feedbackClose() {
			this.$emit('updateMeeting', { fields: { minutes_status: 'done' }, message: 'Thanks for your feedback 😢' })
		},

		async feedbackSend() {
			let feedbackArr = this.states.activeFeedbackOptions.map(k => this.feedbackOptions[k].text)

			// replace "Other" with value from text-area if needed
			if (this.states.activeFeedbackOptions.includes('other')) {
				feedbackArr = feedbackArr.filter(option => option !== 'Other')
				feedbackArr.push(`"${this.feedback.message}"`)
			}

			const resp = await hgApi(`${this.j.slug}/meetings/${this.meeting.pid}/feedback`, {
				json: {
					rating: this.feedback.rating,
					message: feedbackArr.join('\n\n'),
				},
			})

			this.states.activeFeedbackOptions = []

			if (resp.ok) {
				this.meeting.minutes_status = 'done'
				if (this.feedback.rating < 5) {
					this.states.showGenerateMinutes = true
				}
				Vue.toasted.success('Thanks for your feedback 🤝')
			} else {
				Vue.toasted.error('Failed to send feedback')
			}
		},
	},
}
</script>
