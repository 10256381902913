<template>
	<span class="badge" :class="paymentStatuses[status].class" :title="paymentStatuses[status].text">{{
		paymentStatuses[status].name
	}}</span>
</template>

<script>
import { Tooltip } from 'bootstrap'
import { paymentStatuses } from '@/actions/payments.js'

// TODO detect when payment.refunded_amount is > 0, and show status "Full/Partial Refund"

export default {
	name: 'PaymentStatus',
	props: {
		payment: Object,
		tooltip: Boolean,
	},
	data() {
		return {
			paymentStatuses,
			status: null,
		}
	},
	mounted() {
		if (this.tooltip) {
			new Tooltip(this.$el)
		}
	},
	created() {
		if (this.payment.status === 'succeeded' && this.payment.refunded) {
			if (this.payment.refunded === this.payment.total_paid) {
				this.status = 'refunded_full'
			} else if (this.payment.refunded >= this.payment.amount) {
				this.status = 'refunded'
			} else {
				this.status = 'refunded_partial'
			}
		} else {
			this.status = this.payment.status
		}
	},
}
</script>
