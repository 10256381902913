<template>
	<div class="page-payments-utilities-settings">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h3 class="my-0">
					<router-link :to="`/${j.slug}/payments`">HeyGov Pay</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
					<router-link :to="`/${j.slug}/payments/${$route.params.departmentId}`">{{
						selectedDepartment ? selectedDepartment.name : '...'
					}}</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-1" />
					<router-link :to="`/${j.slug}/payments/${$route.params.departmentId}/billing-accounts`"
						>Billing Accounts</router-link
					>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-1" />
					{{ billingAccount ? billingAccount.account_number : $route.params.accountId }}
				</h3>
			</div>
			<div class="col-auto">
				<button
					class="btn btn-sm btn-outline-dark"
					data-bs-toggle="modal"
					data-bs-target="#modal-account-edit"
					@click="editBillingAccount"
				>
					Edit account
				</button>
			</div>
		</div>

		<div v-if="states.billingAccount === 'loaded'">
			<div class="card mb-4">
				<div class="card-body">
					<h4 class="mb-3">
						Billing account <code>{{ billingAccount.account_number }}</code>
					</h4>

					<p class="mb-2"><span class="text-neutral-400">Name:</span> {{ billingAccount.name }}</p>
					<p class="mb-2">
						<span class="text-neutral-400">Service address:</span>
						{{ billingAccount.address | formatAddress }}
					</p>
					<p class="mb-2">
						<span class="text-neutral-400">Mailing address:</span>
						{{ billingAccount.mailing_address | formatAddress }}
					</p>
					<p class="mb-2">
						<span class="text-neutral-400">Balance:</span> {{ billingAccount.balance | currency }}
						<span v-if="billingAccount.balance < 0" class="text-muted ms-1"
							>This will be credited to the next bill</span
						>
					</p>
					<p class="card-text">
						<span class="text-neutral-400 me-2">HeyGov account:</span>

						<person-link v-if="billingAccount.person_id" :id="billingAccount.person_id" :avatar="18" />
						<span v-else>
							<i class="text-neutral-500 me-2">None attached</i
							><button
								class="btn btn-sm btn-outline-dark"
								data-bs-toggle="modal"
								data-bs-target="#modal-account-edit"
								@click="editBillingAccount"
							>
								Add account
							</button>
						</span>
					</p>
				</div>
			</div>

			<div class="row">
				<div class="col-lg-8">
					<div class="card">
						<div class="card-body">
							<div class="row mb-2">
								<div class="col">
									<h5 class="mb-3">Bills</h5>
								</div>
								<div v-if="billingAccount.bills.length" class="col-auto">
									<button
										class="btn btn-sm btn-outline-primary"
										data-bs-toggle="modal"
										data-bs-target="#modal-billing-accounts-add-bill"
									>
										<font-awesome-icon :icon="['fas', 'plus']" class="me-1" /> Add bill
									</button>
								</div>
							</div>

							<div
								v-for="(bill, billIndex) in billingAccount.bills"
								:key="bill.id"
								class="card bg-light mb-3"
							>
								<div class="card-body">
									<div class="row">
										<div class="col-lg-6">
											<p class="lead mb-2">
												<span class="text-neutral-400">Bill date:</span>
												{{ bill.bill_date | dateLocal('en-US', { timeZone: j.timezone }) }}
											</p>
											<p class="mb-2">
												<span class="text-neutral-400">Name:</span> {{ bill.name }}
											</p>
											<p class="mb-2">
												<span class="text-neutral-400">Address:</span>
												{{ bill.address | formatAddress }}
											</p>
										</div>
										<div class="col-lg-6">
											<p class="mb-2">
												<span class="text-neutral-400">Due date:</span>
												{{ bill.bill_due_date | dateLocal('en-US', { timeZone: j.timezone }) }}
											</p>
											<p v-if="bill.bill_period_start" class="mb-2">
												<span class="text-neutral-400">Period start:</span>
												{{
													bill.bill_period_start
														| dateLocal('en-US', { timeZone: j.timezone })
												}}
											</p>
											<p v-if="bill.bill_period_end" class="mb-2">
												<span class="text-neutral-400">Period end:</span>
												{{
													bill.bill_period_end | dateLocal('en-US', { timeZone: j.timezone })
												}}
											</p>
										</div>
									</div>

									<div class="card-table mb-3 mt-3">
										<table class="table">
											<tbody>
												<tr
													v-for="lineItem in bill.line_items"
													:key="`bill-${bill.id}-${lineItem.name}`"
												>
													<td>{{ lineItem.name }}</td>
													<td>{{ lineItem.amount | currency }}</td>
												</tr>
												<tr v-if="!bill.line_items.length">
													<td colspan="2">
														<i class="text-neutral-300">(no line items found)</i>
													</td>
												</tr>
												<tr>
													<td class="text-neutral-500">
														Total
													</td>
													<td>
														<p class="mb-1">
															<strong>{{ bill.amount | currency }}</strong>
														</p>

														<p
															v-for="payment in bill.payments"
															:key="`bill-${bill.id}-payment-${payment.id}`"
															class="mb-1"
														>
															<span class="badge bg-success-50 text-success-300">
																Paid {{ payment.amount | currency }} (+{{
																	payment.fee | currency
																}}
																fee) on
																{{
																	payment.captured_at ||
																		payment.updated_at | dateLocal
																}}
															</span>
														</p>
													</td>
												</tr>
												<tr
													v-if="
														billIndex === 0 &&
															sum(bill.payments.map(p => p.amount)) < bill.amount
													"
												>
													<td colspan="2" class="text-end">
														<button class="btn btn-sm btn-primary disabled ms-2">
															Send to Card Reader
														</button>
														<button class="btn btn-sm btn-primary disabled ms-2">
															Send email notification
														</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									<p
										v-if="bill.text"
										class="card-text text-neutral-500"
										v-html="bill.text.replace(/\n/g, '<br>')"
									></p>
								</div>
							</div>

							<div
								v-if="!billingAccount.bills.length"
								class="border border-dashed rounded-1 bg-neutral-50 px-3 py-4 text-center cursor-pointer"
								data-bs-toggle="modal"
								data-bs-target="#modal-billing-accounts-add-bill"
							>
								<p class="text-neutral-400 mb-2">There are no bills for this Billing Account</p>
								<p class="text-primary mb-0">Add a bill now</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="card">
						<div class="card-header">
							<h5 class="my-0">Account history</h5>
						</div>
						<div class="card-body pt-0 px-3">
							<activity-timeline :activities="billingAccount.activities"></activity-timeline>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else-if="states.billingAccount == 'loading'" class="text-center py-6">
			<span class="spinner-border spinner-border-sm"></span>
		</div>

		<div class="modal fade" id="modal-account-edit" tabindex="-1" aria-hidden="true" ref="editBillingAccountModal">
			<div class="modal-dialog modal-lg">
				<div v-if="billingAccountEdit" class="modal-content">
					<form @submit.prevent="saveBillingAccount">
						<div class="modal-header">
							<h5 class="modal-title">Edit account "{{ billingAccountEdit.account_number }}"</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-group mb-3">
								<label for="billing-account-number" class="form-label">Account number</label>

								<input
									type="text"
									class="form-control"
									id="billing-account-number"
									v-model="billingAccountEdit.account_number"
									required
									minlength="9"
								/>
							</div>

							<div class="form-group mb-3">
								<label for="billing-account-name" class="form-label">Name</label>

								<input
									type="text"
									class="form-control"
									id="billing-account-name"
									v-model="billingAccountEdit.name"
									required
								/>
							</div>

							<div class="form-group mb-3">
								<label for="billing-account-balance" class="form-label">Balance</label>

								<input
									type="number"
									class="form-control"
									id="billing-account-balance"
									v-model="billingAccountEdit.balance"
									required
									step="0.01"
								/>
							</div>

							<div class="form-group mb-3">
								<label for="billing-account-address" class="form-label">Address</label>

								<div class="form-floating mb-3">
									<input
										type="text"
										class="form-control"
										id="account-address-line1"
										placeholder="Street address"
										v-model="billingAccountEdit.address.line1"
									/>
									<label for="account-address-line1">Street address</label>
								</div>

								<div class="form-floating mb-3">
									<input
										type="text"
										class="form-control"
										id="account-address-line2"
										placeholder="Apartment, suite, etc. (optional)"
										v-model="billingAccountEdit.address.line2"
									/>
									<label for="account-address-line2">Apartment, suite, etc. (optional)</label>
								</div>

								<div class="row gx-3">
									<div class="col-12 col-lg-4">
										<div class="form-floating mb-3">
											<input
												type="text"
												class="form-control"
												id="account-address-locality"
												:placeholder="capitalize(j.type)"
												v-model="billingAccountEdit.address.city"
												required
											/>
											<label for="account-address-locality text-capitalize">{{
												capitalize(j.type)
											}}</label>
										</div>
									</div>
									<div class="col-6 col-lg-4">
										<div class="form-floating mb-3">
											<select
												type="text"
												class="form-select"
												id="account-address-state"
												placeholder="State"
												v-model="billingAccountEdit.address.state"
												required
											>
												<option v-for="(label, code) in usStates" :key="code" :value="code">{{
													label
												}}</option></select
											>
											<label for="account-address-state">State</label>
										</div>
									</div>
									<div class="col-6 col-lg-4">
										<div class="form-floating mb-3">
											<input
												type="text"
												class="form-control"
												id="account-address-postal-code"
												placeholder="Postal code"
												v-model="billingAccountEdit.address.postal_code"
												required
												inputmode="numeric"
												minlength="5"
											/>
											<label for="account-address-postal-code">ZIP code</label>
										</div>
									</div>
								</div>
							</div>

							<div class="form-group mb-3">
								<label for="billing-account-person" class="form-label">HeyGov account</label>

								<div v-if="billingAccountEdit.person_id">
									<person-link :id="billingAccountEdit.person_id" :avatar="18" />
									<button
										type="button"
										class="btn btn-sm text-danger-300 ms-2"
										@click="removeBillingAccountPerson"
									>
										Unlink account
									</button>
								</div>
								<div v-else>
									<people-search @person="setBillingAccountPerson"></people-search>
								</div>
							</div>
						</div>
						<div class="modal-footer justify-content-between">
							<button type="button" class="btn btn-sm text-danger-400" data-bs-dismiss="modal">
								Cancel
							</button>
							<button class="btn btn-primary">Save changes</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="modal fade" id="modal-billing-accounts-add-bill" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<form @submit.prevent="addBill">
						<div class="modal-header">
							<h5 class="modal-title">
								Add bill
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div v-if="billingAccount" class="modal-body">
							<p>
								Create a bill for Billing Account <code>{{ billingAccount.account_number }}</code>
							</p>

							<div class="form-group mb-3">
								<label for="billing-account-bill-number" class="form-label">Bill number</label>

								<input
									type="text"
									class="form-control"
									id="billing-account-bill-number"
									placeholder="1234"
									v-model="billNew.bill_number"
									required
								/>
							</div>

							<div class="row">
								<div class="col-lg-6 form-group mb-3">
									<label for="billing-account-bill-date" class="form-label">Bill date</label>

									<input
										type="date"
										class="form-control"
										id="billing-account-bill-date"
										v-model="billNew.bill_date"
										required
									/>
								</div>

								<div class="col-lg-6 form-group mb-3">
									<label for="billing-account-bill-due-date" class="form-label">Bill due date</label>

									<input
										type="date"
										class="form-control"
										id="billing-account-bill-due-date"
										v-model="billNew.bill_due_date"
									/>
								</div>
							</div>

							<div class="form-group mb-3">
								<label for="billing-account-bill-name" class="form-label">Name</label>

								<input
									type="text"
									class="form-control"
									id="billing-account-bill-name"
									placeholder="Resident, company, or organization name"
									v-model="billNew.name"
									required
								/>
							</div>

							<div class="form-group">
								<label for="billing-account-address-line1" class="form-label">Address</label>

								<gmap-autocomplete
									@place_changed="setBillingAccountBillAddress"
									:select-first-on-enter="true"
									:options="autocompleteOptions"
								>
									<template v-slot:default="slotProps">
										<div class="form-floating mb-3">
											<input
												id="billing-account-address-line1"
												class="form-control"
												ref="input"
												:value="billNew.address.line1"
												placeholder="Street address"
												required
												v-on="slotProps.listeners"
												@focus="states.address = 'idle'"
												@blur="setBillingAccountAddressBillPart"
											/>
											<label for="billing-account-address-line1">Address</label>
										</div>
									</template>
								</gmap-autocomplete>

								<div class="form-floating mb-3">
									<input
										type="text"
										class="form-control"
										id="billing-account-address-line2"
										placeholder="Apartment, suite, etc. (optional)"
										v-model="billNew.address.line2"
									/>
									<label for="billing-account-address-line2">Apartment, suite, etc. (optional)</label>
								</div>

								<div class="row gx-3">
									<div class="col-12 col-lg-5">
										<div class="form-floating mb-3">
											<input
												type="text"
												class="form-control"
												id="billing-account-address-city"
												placeholder="City"
												v-model="billNew.address.city"
												required
											/>
											<label for="billing-account-address-city">City</label>
										</div>
									</div>
									<div class="col-6 col-lg-4">
										<div class="form-floating mb-3">
											<select
												class="form-select"
												id="billing-account-address-state"
												placeholder="State"
												v-model="billNew.address.state"
												required
											>
												<option v-for="(label, code) in usStates" :key="code" :value="code">{{
													label
												}}</option>
											</select>
											<label for="billing-account-address-state">State</label>
										</div>
									</div>
									<div class="col-6 col-lg-3">
										<div class="form-floating mb-3">
											<input
												type="text"
												class="form-control"
												id="billing-account-address-postal-code"
												placeholder="Postal code"
												v-model="billNew.address.postal_code"
												required
												inputmode="numeric"
												minlength="5"
											/>
											<label for="billing-account-address-postal-code">ZIP code</label>
										</div>
									</div>
								</div>
							</div>

							<div class="form-group mb-1">
								<label for="billing-account-bill-line-items" class="form-label">Line items</label>

								<div
									v-for="(item, index) in billNew.line_items"
									:key="index"
									class="row align-items-center gx-3 mb-1"
								>
									<div class="col-6">
										<input
											type="text"
											class="form-control form-control-sm"
											v-model="item.name"
											required
										/>
									</div>
									<div class="col">
										<input
											type="number"
											class="form-control form-control-sm"
											v-model="item.amount"
											required
											step="0.01"
										/>
									</div>
									<div v-if="billNew.line_items.length > 1" class="col-auto">
										<button
											type="button"
											class="btn btn-sm btn-icon-danger"
											@click="removeBillLineItem(index)"
										>
											<font-awesome-icon :icon="['fas', 'times']" />
										</button>
									</div>
								</div>
							</div>

							<p>
								<button
									type="button"
									class="btn btn-sm text-primary"
									@click="billNew.line_items.push({ name: null, amount: null })"
								>
									Add line item
								</button>
							</p>

							<div class="form-group mb-3">
								<label for="billing-account-bill-amount" class="form-label">Amount</label>

								<input
									type="number"
									class="form-control"
									id="billing-account-bill-amount"
									placeholder="Amount that needs to be paid"
									v-model="billNew.amount"
									required
									step="0.01"
								/>
							</div>
						</div>
						<div class="modal-footer justify-content-end">
							<button class="btn btn-primary px-5">
								Add bill
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { Modal } from 'bootstrap'
import { sum, capitalize } from 'lodash-es'

import heyGovApi from '@/api.js'
import { handleResponseError } from '@/utils.js'
import { usStates } from '@/lib/geo.js'

import ActivityTimeline from '@/components/ActivityTimeline.vue'
import PersonLink from '@/components/PersonLink.vue'
import PeopleSearch from '@/components/PeopleSearch.vue'

export default {
	name: 'QuickPaySettings',
	components: {
		ActivityTimeline,
		PersonLink,
		PeopleSearch,
	},
	data() {
		return {
			states: {
				billingAccount: 'loading',
			},
			billingAccount: null,
			billingAccountEdit: null,
			$modalEditBillingAccount: null,

			$modalBillingAccountAddBill: null,
			billNew: {
				bill_number: '',
				name: '',
				address: {
					line1: '',
					line2: '',
					city: '',
					state: '',
					postal_code: '',
					country: 'US',
				},
				amount: null,
				bill_date: null,
				bill_due_date: null,
				text: '',
				line_items: [
					{
						name: '',
						amount: null,
					},
				],
			},
			autocompleteOptions: {
				componentRestrictions: { country: 'us' },
				fields: ['geometry', 'address_components', 'url', 'place_id'],
			},
			usStates,
		}
	},
	computed: {
		...mapState(['j', 'departments', 'people']),
		...mapGetters(['currentRole']),
		selectedDepartment() {
			return (this.departments || []).find(d => d.id == this.$route.params.departmentId)
		},
	},
	created() {
		if (this.currentRole === 'CITIZEN') {
			this.$router.push(`/${this.j.slug}/quick-pay/billing-account/${this.$route.params.accountId}`)
		} else {
			this.$store.dispatch('loadDepartments')
			this.loadBillingAccount()
		}
	},
	mounted() {
		this.$modalEditBillingAccount = new Modal('#modal-account-edit')
		this.$modalBillingAccountAddBill = new Modal('#modal-billing-accounts-add-bill')
	},
	methods: {
		capitalize,
		sum,

		loadBillingAccount() {
			this.states.billingAccount = 'loading'

			const params = {
				expand: 'bills,activities',
			}

			heyGovApi(`${this.j.slug}/billing-accounts/${this.$route.params.accountId}`, {
				params,
			}).then(({ data }) => {
				this.billingAccount = data
				this.states.billingAccount = 'loaded'

				this.billNew.name = data.name
				this.billNew.address = window.structuredClone(data.address)

				this.$store.dispatch(
					'loadPeople',
					data.activities.map(a => a.person_id)
				)
			}, handleResponseError(`Couldn't load utility account ({error})`))
		},
		editBillingAccount() {
			this.billingAccountEdit = {
				account_number: this.billingAccount.account_number,
				name: this.billingAccount.name,
				balance: this.billingAccount.balance,
				address: window.structuredClone(this.billingAccount.address),
				person_id: this.billingAccount.person_id,
			}
		},
		setBillingAccountPerson(person) {
			this.billingAccountEdit.person_id = person.id
		},
		removeBillingAccountPerson() {
			this.billingAccountEdit.person_id = null
		},
		saveBillingAccount() {
			heyGovApi
				.put(`${this.j.slug}/billing-accounts/${this.$route.params.accountId}`, this.billingAccountEdit)
				.then(() => {
					this.billingAccount = {
						...this.billingAccount,
						...this.billingAccountEdit,
					}
					this.$modalEditBillingAccount.hide()

					Vue.toasted.success('Billing account saved')
				}, handleResponseError(`Couldn't save billing account ({error})`))
		},

		setBillingAccountBillAddress(place) {
			let street = ''
			let route = ''

			place.address_components.forEach(part => {
				if (part.types.includes('street_number')) {
					street = part.long_name
				} else if (part.types.includes('route')) {
					route = part.long_name
				} else if (part.types.includes('locality')) {
					this.billNew.address.city = part.long_name
				} else if (part.types.includes('administrative_area_level_1')) {
					this.billNew.address.state = part.short_name
				} else if (part.types.includes('postal_code')) {
					this.billNew.address.postal_code = part.long_name
				} else if (part.types.includes('country')) {
					this.billNew.address.country = part.short_name
				}
			})

			this.billNew.address.line1 = `${street} ${route}`.trim()
			this.billNew.address.lat = place.geometry.location.lat()
			this.billNew.address.lng = place.geometry.location.lng()
		},
		setBillingAccountAddressBillPart($event) {
			this.billNew.address.line1 = $event.target.value
		},
		removeBillLineItem(index) {
			const lineItem = this.billNew.line_items[index]
			let empty = !lineItem.name && !lineItem.amount

			if (empty || confirm('Are you sure you want to remove this line item?')) {
				this.billNew.line_items.splice(index, 1)
			}
		},
		addBill() {
			heyGovApi
				.post(`${this.j.slug}/billing-accounts/${this.billingAccount.uuid}/bills`, this.billNew)
				.then(({ data }) => {
					this.billingAccount.bills.unshift(data)
					Vue.toasted.success(`Bill ${data.bill_number} added`)
					this.$modalBillingAccountAddBill.hide()
				}, handleResponseError(`Couldn't add bill ({error})`))
		},
	},
	watch: {},
}
</script>
